import i18n from 'i18next';
import XHR from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import { __env } from '../../envloader';

i18n
  .use(XHR)
  .use(LanguageDetector)
  .init({
    fallbackLng: 'en',
    backend: {
      loadPath: lngs => 
        lngs?.[0]?.startsWith('pl') ?
          `${__env.PUBLIC_URL}/locales/pl/{{ns}}.json` :
          `${__env.PUBLIC_URL}/locales/en/{{ns}}.json`
    },
    // have a common namespace used around the full app
    ns: [ 'common', 'example-app', 'richTextTranslations' ],
    defaultNS: 'common',
    saveMissing: true,
    missingKeyHandler: function (lng, ns, key, fallbackValue) {
      console.warn('Missing key: ' + key + '\nLang: [' + lng + ']\nNamespace: [' + ns + ']');
    },

    debug: false,
    interpolation: {
      escapeValue: false, // not needed for react!!
      formatSeparator: ',',
      format: function (value, format, lng) {
        if (format === 'uppercase') return value.toUpperCase();
        return value;
      }
    },
    react: {
      bindI18n: 'languageChanged loaded',
    }
  });

i18n.on('languageChanged', function(lng) {
  document.documentElement.lang = lng;
  document.title = i18n.t('app_title');
});

export default i18n;
