import _ from 'lodash';

import * as request from '../actions/request';

const initialRequestState = {
  data: undefined,
  response: undefined,
  error: false,
  loading: false,
};

const requestReducer = (previousState, action) => {
  const state = _.cloneDeep(previousState || {});
  if (Object.values(request).includes(action.type)) {
    if (!state[action.id]) {
      state[action.id] = initialRequestState;
    }
  }
  switch (action.type) {
  case request.SET_REQUEST_DATA:
    state[action.id].data = action.value;
    break;
  case request.SET_REQUEST_RESPONSE:
    state[action.id].response = action.value;
    break;
  case request.SET_REQUEST_ERROR:
    state[action.id].error = action.value;
    break;
  case request.SET_REQUEST_LOADING:
    state[action.id].loading = action.value;
    break;
  case request.SET_REQUEST_METHOD:
    state[action.id].method = action.value;
    break;
  case request.SET_REQUEST_ADDRESS:
    state[action.id].address = action.value;
    break;
  case request.SET_REQUEST_BASEURL:
    state[action.id].baseurl = action.value;
    break;
  case request.SET_REQUEST_RETRY:
    state[action.id].retry = action.value;
    break;
  case request.CLEAR_REQUEST:
    delete state[action.id];
    break;
  default:
  }
  return state;
};
export default requestReducer;
