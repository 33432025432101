import React, { useState } from 'react';
import { Navbar, Nav, Button/*, NavDropdown*/ } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { NavLink, Route } from 'react-router-dom';
import PropTypes from 'prop-types';

import useErrorBoundary from '../hooks/useErrorBoundary';
import Login from '../../main/containers/login';
// import {
//   URL_PORTAL_PRODUCTS_LIST,
// } from '../urls';
import useUserToken from '../hooks/useUserToken';

const Menu = props => {

  const { t, i18n } = useTranslation([ 'common'/*, 'menu'*/ ]);
  const { ErrorBoundary } = useErrorBoundary('Menu', true);
  const [ exp, setExp ] = useState(false);
  const { isLoggedIn, isLoginInProgress } = useUserToken();

  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
  };

  return (
    <ErrorBoundary>
      {!isLoginInProgress && !isLoggedIn &&
        <Navbar bg="secondary" variant="dark" fixed="top" expand="xl" expanded={exp} onToggle={e => { }}>
          <Navbar.Brand className="mr-auto">{t('name')}</Navbar.Brand>
        </Navbar >
      }
      {!isLoginInProgress && isLoggedIn &&
        <Navbar bg="secondary" variant="dark" fixed="top" expand="xl" expanded={exp} onToggle={e => { }}>
          <Navbar.Toggle aria-controls="main-menu" onClick={() => setExp(!exp)} className="mr-2"></Navbar.Toggle>
          <Navbar.Brand as={NavLink} exact to="/" className="mr-auto">{t('name')}</Navbar.Brand>
          <Navbar.Collapse id="main-menu">
            <Nav variant="pills" className="ml-2">
              {/* {(isAdmin || isSuperadmin) &&
                <Nav.Item>
                  <Nav.Link as={NavLink} to={URL_PORTAL_OFFERS_LIST} onClick={() => setExp(false)}>{t('menu:offers')}</Nav.Link>
                </Nav.Item>
              } */}
              {/* {(isAdmin || isSuperadmin || isOperator) &&
                <NavDropdown title={t('menu:use_of_resources')}>
                  <NavDropdown.Item as={NavLink} to={URL_PORTAL_USERS_LIST} onClick={() => setExp(false)}>{t('menu:users')}</NavDropdown.Item>
                  <NavDropdown.Item as={NavLink} to={URL_PORTAL_CONTRACTS_LIST} onClick={() => setExp(false)}>{t('menu:contracts')}</NavDropdown.Item>
                  <NavDropdown.Item as={NavLink} to={URL_PORTAL_PROJECTS_LIST} onClick={() => setExp(false)}>{t('menu:projects')}</NavDropdown.Item>
                </NavDropdown>
              } */}
            </Nav>
          </Navbar.Collapse>
        </Navbar >
      }
      <Navbar bg="secondary" variant="dark" style={{ padding: "0" }}>
        <Nav variant="pills" className="ml-auto" style={{ position: "fixed", right: "16px", top: "8px", zIndex: "1030", whiteSpace: "nowrap" }}>
          <Route strict path='/' render={props => (
            <div className="login-menu">
              <Login location={props.location} />
            </div>
          )} />
          <Nav.Item className="ml-1">
            <Button id="lang-en-btn" onClick={() => {
              changeLanguage('en');
              setExp(false);
            }} variant="secondary" active={i18n.language.startsWith('en')}>EN</Button>
            <Button id="lang-pl-btn" onClick={() => {
              changeLanguage('pl');
              setExp(false);
            }} variant="secondary" active={i18n.language.startsWith('pl')}>PL</Button>
          </Nav.Item>
        </Nav>
      </Navbar >
    </ErrorBoundary>
  );
};

Menu.propTypes = {
  location: PropTypes.object
};

export default Menu;
