import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import IdTokenVerifier from 'idtoken-verifier';

import { getGV } from '../../main/utils/globalVariablesProvider';
import {
  USER_ROLE_OPERATOR,
  USER_ROLE_SUPERADMIN,
  USER_ROLE_ADMIN,
} from '../settings';
import { __env } from '../../envloader/index';

const useUserToken = () => {
  const [ isSuperadmin, setIsSuperadmin ] = useState(false);
  const [ isOperator, setIsOperator ] = useState(false);
  const [ isAdmin, setIsAdmin ] = useState(false);
  const [ isLoggedIn, setIsLoggedIn ] = useState(false);
  const [ name, setName ] = useState("");
  const [ username, setUsername ] = useState("");
  const [ email, setEmail ] = useState("");
  const isLoginInProgress = useSelector(state => state.login.get('login_finishing_in_progress'));
  const token = getGV('accessToken');

  useEffect(() => {
    if (token) {
      const verifier = new IdTokenVerifier();
      const decoded = verifier.decode(token)?.payload;
      setIsLoggedIn(false);
      setIsSuperadmin(false);
      setIsOperator(false);
      setIsAdmin(false);
      if (decoded) {
        setName(decoded.name);
        setUsername(decoded.username);
        setEmail(decoded.email);
        setIsLoggedIn(true);
        if (decoded.resource_access && decoded.resource_access[__env.AUTH_CLIENT_ID]) {
          if (Array.isArray(decoded.resource_access[__env.AUTH_CLIENT_ID].roles)) {
            setIsSuperadmin(decoded.resource_access[__env.AUTH_CLIENT_ID].roles.includes(USER_ROLE_SUPERADMIN));
            setIsOperator(decoded.resource_access[__env.AUTH_CLIENT_ID].roles.includes(USER_ROLE_OPERATOR));
            setIsAdmin(decoded.resource_access[__env.AUTH_CLIENT_ID].roles.includes(USER_ROLE_ADMIN));
          }
        }
      }
    }
  }, [ token ]);

  return { isSuperadmin, isAdmin, isOperator, isLoggedIn, isLoginInProgress, name, username, email };
};
export default useUserToken;
