import React, { useRef, Component, Suspense } from 'react';
import PropTypes from 'prop-types';

import { ErrorBoundary } from '../../main/hoc/errorboundary';
import Loading from '../utils/Loader';

const useErrorBoundary = (displayName, isModal = false) => {

  @ErrorBoundary(() => displayName, isModal)
  class MyErrorBoundary extends Component {
    render() {
      return this.props.children;
    }
  }

  MyErrorBoundary.propTypes = { children: PropTypes.node };

  const createWrappedErrorBoundary = () =>
    props => 
      <Suspense fallback={<Loading />}>
        <MyErrorBoundary {...props} displayName={displayName} isModal={isModal} />
      </Suspense>;
  const errorBoundaryWrapperRef = useRef(createWrappedErrorBoundary());

  return { ErrorBoundary: errorBoundaryWrapperRef.current };
};
export default useErrorBoundary;
