export const DEFAULT_PAGINATION_PER_PAGE_SET = [ 10, 20, 50, 100, 200 ];
export const DEFAULT_PAGINATION_PER_PAGE = 10;

export const MAX_ELEMENTS_IN_COMBOBOX_LIST = 256;

export const USER_ROLE_SUPERADMIN = 'admin';
export const USER_ROLE_OPERATOR = 'operator';
export const USER_ROLE_ADMIN = 'user';

export const PING_STRING = '**ping**';
export const PONG_STRING = '**pong**';
export const PING_INTERVAL = 20000; // ms

export const DEFAULT_SERVER_ALIVE_INTERVAL = 60; // s
