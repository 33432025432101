import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Spinner, Form, FormLabel } from 'react-bootstrap';
import { Terminal } from 'xterm';
import 'xterm/css/xterm.css';
import { useQueryParam, NumberParam } from 'use-query-params';
import { FitAddon } from 'xterm-addon-fit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoon, faSun } from '@fortawesome/free-solid-svg-icons';

import useErrorBoundary from '../hooks/useErrorBoundary';
import useUserToken from '../hooks/useUserToken';
import { __env } from '../../envloader/index';
import { URL_SSH_CA_SIGN } from '../urls';
import { error as errorMsg } from '../../main/utils/notify';
import useApi from '../hooks/useApi';
import ssh from 'ed25519-keygen/ssh';
import { randomBytes } from 'ed25519-keygen/utils';
import useAssistant from '../hooks/useAssistant';
import { PING_INTERVAL, PING_STRING, PONG_STRING, DEFAULT_SERVER_ALIVE_INTERVAL } from '../settings';
import { THEME_MODE } from '../stateIds';
import useStateRedux from '../hooks/useStateRedux';

const HomeContainer = () => {
  const [ t ] = useTranslation('home', 'common');
  const { ErrorBoundary } = useErrorBoundary('Home Container', true);
  const { setValue: setMode, value: mode } = useStateRedux(THEME_MODE, window.localStorage.getItem('mode') === 'true' ? true : false);
  const termRef = useRef(null);
  const terminal = useRef(null);
  const fitAddon = useRef(null);
  const homeContainer = useRef(null);
  const ws = useRef(null);
  const pingPongHandler = useRef(null);
  const [ connected, setConnected ] = useState(false);
  const [ connecting, setConnecting ] = useState(false);
  const [ target, setTarget ] = useQueryParam('id', NumberParam);
  const [ initTarget, setInitTarget ] = useState(target);
  const { username, email } = useUserToken();
  const { refetch: sendToCA } = useApi('post', URL_SSH_CA_SIGN, __env.SSH_CA_SERVER_URL);
  const [ SSH_TARGET_SERVERS ] = useState((__env.SSH_SERVERS || '').split(',').map(v => ({
    name: v.split('|')[1] || v.split('|')[0] || 'localhost',
    options: {
      port: v.split('|')[0]?.split(':')[1] || 22,
      host: v.split('|')[0]?.split(':')[0] || '127.0.0.1'
    }
  })));
  const { ModalComponent, AssistantButton } = useAssistant();
  const sendPing = useCallback(() => {
    if (ws.current?.readyState === 1) { // OPEN
      ws.current.send(PING_STRING);
    }
  }, []);

  // create and setup terminal
  useEffect(() => {
    terminal.current = new Terminal({
      // convertEol: true,
      cursorBlink: true,
    });
    fitAddon.current = new FitAddon();
    terminal.current.loadAddon(fitAddon.current);
    terminal.current.open(termRef.current);
    fitAddon.current.fit();
    terminal.current.onData((data) => {
      clearInterval(pingPongHandler.current);
      pingPongHandler.current = setInterval(sendPing, PING_INTERVAL);
      ws.current.send(data);
    });

    /**
     * Attaches a custom key event handler which is run before keys are
     * processed, giving consumers of xterm.js ultimate control as to what keys
     * should be processed by the terminal and what keys should not.
     * @param customKeyEventHandler The custom KeyboardEvent handler to attach.
     * This is a function that takes a KeyboardEvent, allowing consumers to stop
     * propagation and/or prevent the default action. The function returns
     * whether the event should be processed by xterm.js.
     *
     * @example A custom keymap that overrides the backspace key
     * ```ts
     * const keymap = [
     *   { "key": "Backspace", "shiftKey": false, "mapCode": 8 },
     *   { "key": "Backspace", "shiftKey": true, "mapCode": 127 }
     * ];
     * term.attachCustomKeyEventHandler(ev => {
     *   if (ev.type === 'keydown') {
     *     for (let i in keymap) {
     *       if (keymap[i].key == ev.key && keymap[i].shiftKey == ev.shiftKey) {
     *         socket.send(String.fromCharCode(keymap[i].mapCode));
     *         return false;
     *       }
     *     }
     *   }
     * });
     * ```
     */
    // attachCustomKeyEventHandler(customKeyEventHandler: (event: KeyboardEvent) => boolean): void;
    // ########### utf8 test!!! #######################
    // echo -e '\xe2\x82\xac'
    // ################################################

    return () => {
      terminal.current?.close?.();
      ws.current?.close?.();
    };
  }, [ sendPing ]);

  useEffect(() => {
    terminal.current.onResize(e => {
      if (connected && ws.current?.readyState === 1) {
        ws.current.send(JSON.stringify({ rows: terminal.current.rows, cols: terminal.current.cols }));
      }
    });
  }, [ connected ]);

  useEffect(() => {
    const resizeScreen = () => {
      fitAddon.current.fit();
    };
    window.addEventListener('resize', resizeScreen, false);
    return () => {
      window.removeEventListener('resize', resizeScreen, false);
    };
  }, []);

  const onConnect = useCallback(() => {
    setConnecting(true);
    document.title = `SSH UI ${SSH_TARGET_SERVERS[target].name}`;
    terminal.current.write(`Trying to open Web Socket on ${__env.WS_PROXY_SERVER_URL} ...\r\n`);
    try {
      ws.current = new WebSocket(__env.WS_PROXY_SERVER_URL);
    } 
    catch (err) {
      terminal.current.write(`Cannot create Web Socket...\r\n`);
    }
    ws.current.addEventListener('message', event => {
      clearInterval(pingPongHandler.current);
      pingPongHandler.current = setInterval(sendPing, PING_INTERVAL);
      if (event.data !== PONG_STRING) {
        terminal.current.write(event.data);
      }
    });
    ws.current.addEventListener('error', () => {
      terminal.current.write('Web socket error\r\n');
    });
    ws.current.addEventListener('close', () => {
      clearInterval(pingPongHandler.current);
      terminal.current.write('\r\nWeb socket closed\r\n');
      setConnecting(false);
      setConnected(false);
    });
    ws.current.addEventListener('open', () => {
      clearInterval(pingPongHandler.current);
      pingPongHandler.current = setInterval(sendPing, PING_INTERVAL);
      terminal.current.write('Web socket opened\r\n');
      const message = SSH_TARGET_SERVERS[target].options;
      if (!message.username) {
        message.username = username;
      }
      message.columns = terminal.current.cols;
      message.lines = terminal.current.rows;
      message.keepalive = DEFAULT_SERVER_ALIVE_INTERVAL;
      terminal.current.write(`Connecting SSH server at ${message.host} on port ${message.port} with user ${message.username} ...\r\n\r\n`);

      const sseed = randomBytes(32);
      ssh(sseed, email).then(skeys => {
        sendToCA({
          public_key: btoa(skeys.publicKey)
        }).then(res => {
          message.privateKey = skeys.privateKey;
          message.certificate = atob(res.data.certificate);
          ws.current.send(JSON.stringify(message));
          setConnected(true);
        }).catch(err => {
          errorMsg(`${t('ssh_ca_api_error')}: ${err}`);
          setConnected(false);
        }).finally(() => {
          setConnecting(false);
        });
      });
    });
    terminal.current.focus();
  }, [ username, target, sendToCA, email, t, SSH_TARGET_SERVERS, sendPing ]);

  const onDisconnect = () => {
    ws.current?.close();
    setConnected(false);
  };

  useEffect(() => {
    let handler = null;
    if (SSH_TARGET_SERVERS[initTarget]) {
      handler = setTimeout(() => {
        setInitTarget(undefined);
        onConnect();
      }, 100);
    }
    if (initTarget !== undefined && !SSH_TARGET_SERVERS[initTarget]) {
      setInitTarget(undefined);
      errorMsg(t('wrong_url_parameter'));
    }
    return () => clearTimeout(handler);
  }, [ initTarget, onConnect, t, SSH_TARGET_SERVERS ]);

  return (
    <ErrorBoundary>
      <div className='home-container' ref={homeContainer}>
        {ModalComponent}
        <div className='form-container'>
          <Form.Group>
            <FormLabel>{t('target_server')}:</FormLabel>
            <Form.Control
              className="w-25"
              as="select"
              onChange={e => {
                setTarget(e.target.value || undefined, 'replaceIn');
              }}
              value={isNaN(target) ? '' : target}
              disabled={connecting || connected}
            >
              <option value=''></option>
              {SSH_TARGET_SERVERS.map((s, indx) =>
                <option key={indx} value={indx}>{s.name}</option>
              )}
            </Form.Control>
            <Button
              type='button'
              disabled={connecting || connected || !target?.toString()?.length}
              onClick={onConnect}
            >Connect
              {connecting &&
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              }
            </Button>
            <Button
              type='button'
              disabled={connecting || !connected}
              onClick={onDisconnect}
            >Disconnect</Button>
          </Form.Group>
          <Form.Group>
            <Button
              onClick={e => {
                window.localStorage.setItem('mode', !mode);
                setMode(!mode);
              }}
              variant='link'
              title={`Switch terminal to ${mode ? 'dark' : 'light'} mode`}
            >
              {mode &&
                <FontAwesomeIcon icon={faMoon} size='xl' />
              }
              {!mode &&
                <FontAwesomeIcon icon={faSun} size='xl' />
              }
            </Button>
          </Form.Group>
          <div className='assistant'>
            {AssistantButton}
          </div>
        </div>
        <div ref={termRef} className='xterm-container'></div>
      </div>
    </ErrorBoundary>
  );
};
export default HomeContainer;
