export const SET_REQUEST_DATA = 'set-request-data';
export const setRequestDataAction = (id, value) => ({ id, value, type: SET_REQUEST_DATA });

export const SET_REQUEST_LOADING = 'set-request-loading';
export const setRequestLoadingAction = (id, value) => ({ id, value, type: SET_REQUEST_LOADING });

export const SET_REQUEST_ERROR = 'set-request-error';
export const setRequestErrorAction = (id, value) => ({ id, value, type: SET_REQUEST_ERROR });

export const SET_REQUEST_RESPONSE = 'set-request-response';
export const setRequestResponseAction = (id, value) => ({ id, value, type: SET_REQUEST_RESPONSE });

export const CLEAR_REQUEST = 'clear-request';
export const clearRequestAction = id => ({ id, type: CLEAR_REQUEST });

export const SET_REQUEST_METHOD = 'set-request-method';
export const setRequestMethodAction = (id, value) => ({ id, value, type: SET_REQUEST_METHOD });

export const SET_REQUEST_ADDRESS = 'set-request-address';
export const setRequestAddressAction = (id, value) => ({ id, value, type: SET_REQUEST_ADDRESS });

export const SET_REQUEST_BASEURL = 'set-request-baseurl';
export const setRequestBaseurlAction = (id, value) => ({ id, value, type: SET_REQUEST_BASEURL });

export const SET_REQUEST_RETRY = 'set-request-retry';
export const setRequestRetryAction = (id, value) => ({ id, value, type: SET_REQUEST_RETRY });

