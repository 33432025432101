import { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Spinner, Button, Form, Card } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faRobot, faUser } from '@fortawesome/free-solid-svg-icons';
import { error as errorMsg } from '../../main/utils/notify';

import { __env } from '../../envloader/index';
import useApi from '../hooks/useApi';
import useUserToken from '../hooks/useUserToken';

const AssistantButton = props => {
  const { t } = useTranslation();
  return (
    <>
      {__env.AI_ASSISTANT_SERVER_URL &&
        <Button onClick={e => props.setShow(true)}>
          {t('assistant')}<FontAwesomeIcon icon={faRobot} className="ml-2" title={t('send')} />
        </Button>
      }
    </>
  );
};
AssistantButton.propTypes = {
  setShow: PropTypes.func
};


const AssistantModal = props => {
  const { t } = useTranslation();
  const { refetch, loading } = useApi('post', '', __env.AI_ASSISTANT_SERVER_URL);
  const { username } = useUserToken();
  const [ prompt, setPrompt ] = useState('');
  const [ convHistory, setConvHistory ] = useState([]);
  const inputRef = useRef();
  const convRef = useRef();
  const bodyRef = useRef();

  const onSend = () => {
    setConvHistory([ ...convHistory, { role: 'u', text: prompt } ]);
    setTimeout(() => {
      bodyRef.current.scroll({ top: convRef.current.scrollHeight, behavior: 'smooth' });
    });
    refetch({
      prompt,
      user: username
    }).then(res => {
      if (res?.data?.content) {
        setConvHistory(old => ([ ...old, { role: 'a', text: res.data.content } ]));
        setTimeout(() => {
          bodyRef.current.scroll({ top: convRef.current.scrollHeight, behavior: 'smooth' });
        });
        inputRef.current.focus();
      }
    }).catch(err => {
      errorMsg(t('assistant_server_error'));
    });
    setPrompt('');
  };
  
  return (
    <Modal
      className='assistant-chat'
      show={props.show}
      onHide={() => props.setShow(false)}
      scrollable={true}
      size='lg'
      onShow={e => {
        bodyRef.current.scroll({ top: convRef.current.scrollHeight, behavior: 'smooth' });
        inputRef.current.focus();
      }}
    >
      <Modal.Header closeButton>{t('chat_with_assistant')}</Modal.Header>
      <Modal.Body ref={bodyRef}>
        <div ref={convRef} className='conv'>
          {convHistory.map((o, indx) =>
            <div key={indx}>
              <Card border={o.role === 'u' ? 'success' : 'info'}>
                <Card.Title className='p-2'>
                  {o.role === 'u' &&
                    <FontAwesomeIcon icon={faUser} />
                  }
                  {o.role === 'a' &&
                    <FontAwesomeIcon icon={faRobot} />
                  }
                </Card.Title>
                <Card.Body>
                  {o.text.split('\n').map((txt, i) =>
                    <div key={i}>{txt}</div>
                  )}
                </Card.Body>
              </Card>
            </div>
          )}
          {loading &&
            <Card border={'info'}>
              <Card.Title className='p-2'>
                <FontAwesomeIcon icon={faRobot} />
              </Card.Title>
              <Card.Body>
                <Card.Text>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    // className="ml-1"
                  />
                </Card.Text>
              </Card.Body>
            </Card>
          }
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Form className='d-flex flex-grow-1' onSubmit={e => e.preventDefault()}>
          <Form.Control
            ref={inputRef}
            className='flex-grow-1 mr-1'
            type="text"
            disabled={loading}
            value={prompt}
            onChange={e => {
              setPrompt(e.target.value);
            }}
          >
          </Form.Control>
          <Button
            type='submit'
            disabled={loading}
            onClick={onSend}
          >
            <FontAwesomeIcon icon={faPaperPlane} title={t('send')} />
          </Button>
        </Form>
      </Modal.Footer>
    </Modal>
  );
};
AssistantModal.propTypes = {
  show: PropTypes.bool,
  setShow: PropTypes.func
};

const useAssistant = () => {
  const [ show, setShow ] = useState(false);

  return {
    ModalComponent: <AssistantModal
      show={show}
      setShow={setShow}
    ></AssistantModal>,
    AssistantButton: <AssistantButton
      setShow={setShow}
    ></AssistantButton>
  };
};
export default useAssistant;
