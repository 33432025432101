import _ from 'lodash';

import {
  SET_STATE_VALUE,
  CLEAR_STATE,
} from '../actions/state';

const stateReducer = (previousState, action) => {
  const state = _.cloneDeep(previousState || {});
  switch (action.type) {
  case SET_STATE_VALUE:
    state[action.id] = action.value;
    break;
  case CLEAR_STATE:
    delete state[action.id];
    break;
  default:
  }
  return state;
};
export default stateReducer;
