import { useState, useCallback, useEffect, useRef } from 'react';
import query from 'query-string';

import axios from '../../main/utils/axios/axiosInstance';
import axiosObj from 'axios';
import { getLogger } from '../../main/utils/logger';
import useTranslationMemo from './useTranslationMemo';

const useApi = (method, address, baseurl, config) => {
  const { i18n } = useTranslationMemo();
  const [ response, setResponse ] = useState({});
  const [ loading, setLoading ] = useState(false);
  const [ error, setError ] = useState(false);
  const [ data, setData ] = useState({});
  const [ initConfig ] = useState(config);
  const controller = useRef();
  const lastSource = useRef();

  useEffect(() => {
    return () => {
      controller.current?.abort();
      lastSource.current?.cancel();
    };
  }, []);

  const refetch = useCallback((data, url = '') => {
    controller.current = new AbortController();
    lastSource.current = axiosObj?.CancelToken?.source();
    setLoading(true);
    setError(false);
    setData(data);
    const fullUrl = `${baseurl?.endsWith('/') ? baseurl?.slice(0, -1) : baseurl}${address}${url}`;
    let fullPath = fullUrl.split('?')[0];
    let fullParams = fullUrl.split('?')[1]; // can be undefined!
    if (!fullParams) {
      fullParams = '';
      if (method === 'get' && data) {
        fullParams = `?${query.stringify(data, { arrayFormat: 'bracket' })}`;
      }
    }
    else {
      fullParams = `?${fullParams}`;
      if (method === 'get' && data) {
        fullParams = `${fullParams}&${query.stringify(data, { arrayFormat: 'bracket' })}`;
      }
    }
    // WARNING!!! String.prototype.replaceAll() is NOT implemented in Node.js thus not supported in jest tests!
    fullParams = fullParams.replace(/\[\]/g, ''); // param array support - names without []
    return axios({ __forceSilent: true, ...initConfig, method, url: `${fullPath}${fullParams}`, data,
      signal: controller.current.signal, cancelToken: lastSource.current?.token })
      .then(res => {
        setResponse(res);
        setError(false);
        setLoading(false);
        return res;
      })
      .catch(err => {
        if (!axiosObj.isCancel(err)) {
          setResponse(err);
          setError(true);
          setLoading(false);
          let msg = 'error';
          switch (err.response?.status) {
          case 400:
            msg = i18n.language.startsWith('en') ? 
              (err?.response?.data?.description?.en || 'Program error') : (err?.response?.data?.description?.pl || 'Błąd programu');
            break;
          case 403:
            msg = i18n.language.startsWith('en') ? 
              (err?.response?.data?.description?.en || 'No permission') : (err?.response?.data?.description?.pl || 'Brak uprawnień');
            break;
          case 404:
            msg = i18n.language.startsWith('en') ? 
              (err?.response?.data?.description?.en || 'Not found') : (err?.response?.data?.description?.pl || 'Nie znaleziono');
            break;
          case 409:
            msg = i18n.language.startsWith('en') ? 
              (err?.response?.data?.description?.en || 'Data error') : (err?.response?.data?.description?.pl || 'Błąd danych');
            break;
          case 422:
            msg = i18n.language.startsWith('en') ? 
              (err?.response?.data?.description?.en || 'Infrastructure error') : (err?.response?.data?.description?.pl || 'Błąd infrastruktury');
            break;
          default:
            msg = i18n.language.startsWith('en') ? 
              (err?.response?.data?.description?.en || 'Program error') : (err?.response?.data?.description?.pl || 'Błąd programu');
          }
          const logger = getLogger({ loggerName: 'admins-ui API call' });
          logger.error(`${msg} [${err.response?.data?.error}]`);
          throw err;
        }
      });
  }, [ address, baseurl, method, initConfig, i18n ]);

  return { response, loading, error, data, refetch };
};
export default useApi;
