import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

const useTranslationMemo = (ns, options) => {
  const { t, i18n, ready } = useTranslation(ns, options);
  const r = useRef(t);

  return { t: r.current, i18n, ready };
};
export default useTranslationMemo;
